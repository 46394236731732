import React from 'react'
import classes from '../components/css/TechWorks.module.css'
import logo from '@/images/headerLogo.svg'
import { Facebook, Insta, Site, Vk, YouTube } from '../images/svg/SocialNetwork'
import SEO from '../components/seo'

const TechWork = () => (
  <div className={classes.container}>
    <SEO title='Технические работы' />
    <img src={logo} alt='logo' className={classes.logo} />
    <div className={classes.text}>
      <h2 className={classes.title}>На сайте ведутся технические работы</h2>
      <p className={classes.description}>
        Совсем скоро сайт откроется, а пока приносим свои извинения.
      </p>
    </div>
    <div className={classes.links}>
      <p className={classes.links__title}>Наши соц. сети:</p>
      <div className={classes.links__social_network}>
        <Site />
        <Facebook />
        <Insta />
        <Vk />
        <YouTube />
      </div>
    </div>
  </div>
)
export default TechWork
